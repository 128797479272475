import { Box, Container, Heading, Stack, Text } from '@chakra-ui/react';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import GradientText from '../components/GradientText/GradientText';
import { Page } from '../components/Page';
import Paragraph from '../components/Paragraph/Paragraph';
import { JURISDICTION, LAST_UPDATE_OF_TERMS, OUR_COMPANY_NAME, OUR_COMPANY_WEBSITE_URL } from '../constants';

export default function Terms() {
  const intl = useIntl();

  return (
    <Page>
      <Container pt={[2, 8]} maxWidth={['100%', '80%']}>
        <Stack shouldWrapChildren>
          <GradientText>{intl.formatMessage({ id: 'pages.terms.title' })}</GradientText>
          <Text textAlign="center">
            {intl.formatMessage({ id: 'pages.terms.lastUpdated' }, { lastUpdatedDate: LAST_UPDATE_OF_TERMS })}
          </Text>
          <Paragraph maxWidth="900px">
            {intl.formatMessage(
              { id: 'pages.terms.description' },
              { companyName: OUR_COMPANY_NAME, companyUrl: OUR_COMPANY_WEBSITE_URL },
            )}
          </Paragraph>
          <div>
            {[...Array(10)].map((_, i) => {
              const index = i + 1;
              return (
                <Box key={index}>
                  <Heading as="h3" size="lg" textAlign="center">
                    {intl.formatMessage({ id: `pages.terms.sections.${index}.title` })}
                  </Heading>
                  {index === 9 ? (
                    <Paragraph maxWidth="900px">
                      {intl.formatMessage(
                        { id: `pages.terms.sections.${index}.description` },
                        { jurisdiction: JURISDICTION },
                      )}
                    </Paragraph>
                  ) : (
                    <Paragraph maxWidth="900px">
                      {intl.formatMessage({ id: `pages.terms.sections.${index}.description` })}
                    </Paragraph>
                  )}
                </Box>
              );
            })}
          </div>
        </Stack>
      </Container>
    </Page>
  );
}
